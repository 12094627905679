.leaflet-container {
    width: 100%;
    height: 80vh;
    margin: auto;
  }

  .left {
    width: 50%;
    height: 100vh;
  }

.halfheight {
    height: 40vh;
}

  .right {
    height: 50%;
    width: 100%;

  }

  .gray .leaflet-tile-pane {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }